@import url(https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
.styles_Header__2HRvL {
  position: fixed;
  top: -65px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s linear;
  background-color: rgb(1, 16, 54, 0.8);
  z-index: 50;
}

.styles_Header__2HRvL.styles_visible__3hc9Q {
  top: 0;
}
.styles_Logo__4C_aA {
  width: 65px;
  height: 65px;
}
.styles_Footer__3smRc {
  padding: 50px 0;
  overflow-y: hidden;
}

.styles_Footer__wrapper__1CfC5 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_Footer__social-link__25RkV {
  color: var(--font-color);
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.styles_Footer__social-link__25RkV:last-child {
  margin-right: 0;
}

.styles_social-link__icon-wrapper__2cwip {
  width: 50px;
  height: 50px;
  background: var(--background-color-button);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease;
}

.styles_Footer__social-link__25RkV:hover .styles_social-link__icon-wrapper__2cwip {
  background-color: var(--font-strong-hover-color);
}

.styles_Footer__social-link__25RkV .styles_social-link__icon__35eMm {
  color: var(--font-color);
}

@media screen and (min-width: 480px) {
  .styles_Footer__3smRc {
    font-size: 17px;
  }

  .styles_social-link__icon-wrapper__2cwip {
    width: 55px;
    height: 55px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Footer__3smRc {
    font-size: 18.5px;
  }

  .styles_social-link__icon-wrapper__2cwip {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Footer__3smRc {
    font-size: 20px;
  }

  .styles_social-link__icon-wrapper__2cwip {
    width: 65px;
    height: 65px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Footer__3smRc {
    font-size: 21.5px;
  }

  .styles_social-link__icon-wrapper__2cwip {
    width: 70px;
    height: 70px;
  }
}

.styles_overlay__2d7r- {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  display: none;
  z-index: 98;
}

.styles_Nav__2mAHh {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  flex-direction: column;
  align-items: flex-end;
  display: none;
}

.styles_overlay__2d7r-.styles_active__2EoNc {
  display: block;
}

.styles_Nav__2mAHh.styles_active__2EoNc {
  display: flex;
}

.styles_Nav__list__q41pt {
  margin-top: 65px;
  padding-right: 17.5px;
  overflow-x: hidden;
}

.styles_Nav__link__1_Nrq {
  color: #a8a9b4;
  width: inherit;
  height: inherit;
  padding: 15px 5px;
  transform: translateX(170px);
  opacity: 0;
  will-change: transform, opacity;
  display: inline-block;
  width: 100%;
  text-align: right;
  font-weight: 700;
  font-size: 1.5em;
}

.styles_Nav__link__1_Nrq:hover, .styles_Nav__link__1_Nrq[aria-current] {
  color: var(--font-color);
}

.styles_Nav__link__1_Nrq:hover .styles_link__icons__n6x8l, .styles_Nav__link__1_Nrq[aria-current] .styles_link__icons__n6x8l {
  color: var(--font-strong-hover-color);
}

.styles_link__icons__n6x8l {
  font-size: 1.1em;
  margin-left: 10px;
}

@media only screen and (min-width: 800px) {
  .styles_Nav__2mAHh {
    position: static;
    width: inherit;
    opacity: 1;
    display: flex;
    flex-direction: row;
  }
  
  .styles_Nav__list__q41pt {
    display: flex;
    margin-top: 0;
    padding-right: 0;
  }
  
  .styles_Nav__link__1_Nrq {
    padding: 10px;
    transform: none;
    opacity: 1;
  }

  .styles_Nav__link__1_Nrq:hover {
    color: var(--font-strong-hover-color);
  }

  .styles_link__icons__n6x8l {
    display: none;
  }
}

.styles_Button__UAVVY {
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
}

.styles_Button__icon__16ymN {
  position: relative;
  width: 30px;
  height: 20px;
}

.styles_Button__icon__16ymN span {
  width: 100%;
  height: 2px;
  background-color: var(--font-color);
  border-radius: 16px;
  position: absolute;
  transform: rotate(0deg);
  transition: all 0.25s;
  will-change: tranform, opacity;
}

.styles_Button__icon__16ymN span:nth-child(1) {
  top: 0;
  left: 0;
}

.styles_Button__icon__16ymN span:nth-child(2) {
  top: 50%;
  left: 0;
}

.styles_Button__icon__16ymN span:nth-child(3) {
  top: 100%;
  left: 0;
}

.styles_Button__UAVVY:hover span,
.styles_Button__UAVVY:active span,
.styles_Button__UAVVY.styles_active__MLpCi span {
  background-color: var(--font-strong-hover-color);
}

/* By clicking... */
.styles_Button__UAVVY.styles_active__MLpCi span:nth-child(1) {
  transform: rotate(225deg);
  top: 50%;
}

.styles_Button__UAVVY.styles_active__MLpCi span:nth-child(2) {
  transform: rotate(225deg);
  top: 50%;
  opacity: 0;
}

.styles_Button__UAVVY.styles_active__MLpCi span:nth-child(3) {
  transform: rotate(315deg);
  top: 50%;
}

/* Hover to indicate close... */
.styles_Button__UAVVY.styles_active__MLpCi:hover span:nth-child(1) {
  transform: rotate(135deg);
  top: 50%;
}

.styles_Button__UAVVY.styles_active__MLpCi:hover span:nth-child(2) {
  transform: rotate(135deg);
  top: 50%;
  opacity: 0;
}

.styles_Button__UAVVY.styles_active__MLpCi:hover span:nth-child(3) {
  transform: rotate(225deg);
  top: 50%;
}

@media only screen and (min-width: 800px) {
  .styles_Button__UAVVY {
    display: none;
  }
}
.styles_overflowY--hidden__vP7JR {
  overflow-y: hidden;
}

.styles_background__1ans7 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to bottom, var(--background-color-top), transparent);
  z-index: -100;
}

.styles_menu__56_XF {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_wrapper__1s9gd {
  padding-top: 100px;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-content: center;
  padding: 100px 10px 0;
}

.styles_section__3EVhJ {
  align-self: center;
  max-width: 850px;
}

.styles_footer-container__Tyn8E {
  align-self: end;
}

@media screen and (min-width: 568px) {
  .styles_wrapper__1s9gd {
    padding: 100px 20px 0;
  }
}
.styles_Loader__1Y-9E {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.styles_Loader--small__2Ttu0 {
  margin: 25px auto;
}

.styles_Loader--big__1d67Y {
  margin: 100px auto;
}

.styles_Loader__rect__3zG2y {
  background-color: var(--font-color);
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: styles_stretch__3e8IF 1.2s infinite ease-in-out;
          animation: styles_stretch__3e8IF 1.2s infinite ease-in-out;
  margin: 0 1px;
}

.styles_Loader__rect--delay-2__he_N8 {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.styles_Loader__rect--delay-3__WNiZ4 {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.styles_Loader__rect--delay-4__2w1LY {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.styles_Loader__rect--delay-5__3N6j5 {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@-webkit-keyframes styles_stretch__3e8IF {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  
  20% { 
    transform: scaleY(1.0);
  }
}

@keyframes styles_stretch__3e8IF {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  
  20% { 
    transform: scaleY(1.0);
  }
}
.styles_Avatar-perfil__2RH2J {
  display: block;
}

.styles_Avatar-perfil__image__2-qUm {
  width: 200px;
  margin-bottom: 25px;
}

.styles_Avatar-perfil__title__cK3H5 {
  font-family: 'Mr Dafoe', cursive;
  color: var(--font-strong-hover-color);
  font-weight: 400;
  font-size: 3em;
  margin-bottom: 25px;
}

@media screen and (min-width: 480px) {

  .styles_Avatar-perfil__2RH2J {
    font-size: 17px;
  }

  .styles_Avatar-perfil__image__2-qUm {
    width: 205px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Avatar-perfil__2RH2J {
    font-size: 18.5px;
  }

  .styles_Avatar-perfil__image__2-qUm {
    width: 220px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Avatar-perfil__2RH2J {
    font-size: 20px;
  }
  
  .styles_Avatar-perfil__image__2-qUm {
    width: 230px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Avatar-perfil__2RH2J {
    font-size: 21.5px;
  }
  
  .styles_Avatar-perfil__image__2-qUm {
    width: 240px;
  }
}
.styles_Hero__1-KjL {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.styles_Hero__image-container__1AzbC {
  width: 200px;
  margin-bottom: 25px;
}

.styles_Hero__image__3dd17 {
  width: 200px;
  margin-bottom: 25px;
}

.styles_Hero__title__240PE {
  font-family: 'Mr Dafoe', cursive;
  color: var(--font-strong-hover-color);
  font-weight: 400;
  font-size: 3em;
  margin-bottom: 25px;
}

.styles_Hero__description__jwTUy {
  font-size: 1.25em;
  margin-bottom: 15px;
}

.styles_Hero__link__3ZNky {
  color: var(--font-color);
  font-size: 0.9em;
  font-weight: 400;
}

.styles_link__arrow-icon__1G5Hn {
  margin-left: 10px;
}

@media screen and (min-width: 480px) {

  .styles_Hero__1-KjL {
    font-size: 17px;
  }

  .styles_Hero__image__3dd17 {
    width: 205px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Hero__1-KjL {
    font-size: 18.5px;
  }
  .styles_Hero__image__3dd17 {
    width: 220px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Hero__1-KjL {
    font-size: 20px;
  }
  
  .styles_Hero__image__3dd17 {
    width: 230px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Hero__1-KjL {
    font-size: 21.5px;
  }
  
  .styles_Hero__image__3dd17 {
    width: 240px;
  }
}
.styles_Skill-figure__1AFVp {
  width: 95px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  transition: 0.15s linear;
  transition-property: box-shadow, transform;
  position: relative;
}

.styles_Skill__process-text__kMn-9 {
  position: absolute;
  top: 12px;
  right: -34px;
  background-color: #1d88ff;
  padding: 0 30px;
  transform: rotateZ(45deg);
  font-size: 0.7em;
  font-weight: 700;
}

.styles_Skill__logo__shNGK {
  width: 35px;
  height: 35px;
  margin: 10px 0 5px;
}

.styles_Skill__logo-description__2SxYH {
  margin-bottom: 5px;
  transition: color 0.15s linear;
}

.styles_Skill-figure__1AFVp:hover .styles_Skill__logo-description__2SxYH {
  color: var(--font-strong-hover-color);
}

.styles_Skill-figure__1AFVp:hover {
  transform: scale(1.05);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 1);
}

@media screen and (min-width: 480px) {
  .styles_Skill__process-text__kMn-9 {
    top: 12px;
    right: -35px;
  }
}

@media screen and (min-width: 568px) {
  .styles_Skill-figure__1AFVp {
    width: 160px;
    height: 150px;
  }

  .styles_Skill__process-text__kMn-9 {
    top: 20px;
    right: -30px;
    font-size: 0.85em;
  }

  .styles_Skill__logo__shNGK {
    width: 60px;
    height: 60px;
  }
}
.styles_Skills-grid__10gCH {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(95px, 1fr));
  justify-items: center;
  grid-gap: 5px;
  gap: 5px;
}

@media screen and (min-width: 568px) {
  .styles_Skills-grid__10gCH {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 10px;
    gap: 10px;
  }
}
.styles_Avatar-greet__2yVaK {
  position: relative;
  z-index: -1;
  width: 300px;
  margin: 0 auto 30px;
}

.styles_Avatar-greet__image__1jhTB {
  width: 175px;
}

.styles_Avatar-greet__text__1gk47 {
  background-color: var(--background-color-bottom);
  border-radius: 45%;
  width: 175px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.styles_Avatar-greet__text__1gk47::after {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid var(--background-color-bottom);
  border-left: 10px solid var(--background-color-bottom);
  border-right: 10px solid transparent;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

@media screen and (min-width: 480px) {
  .styles_Avatar-greet__2yVaK {
    font-size: 17px;
    width: 320px;
  }
  
  .styles_Avatar-greet__image__1jhTB, .styles_Avatar-greet__text__1gk47 {
    width: 185px;
  }

  .styles_Avatar-greet__text__1gk47::after {
    border-top: 11px solid transparent;
    border-bottom: 11px solid var(--background-color-bottom);
    border-left: 11px solid var(--background-color-bottom);
    border-right: 11px solid transparent;
    left: 11px;
    bottom: 9px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Avatar-greet__2yVaK {
    font-size: 18.5px;
    width: 350px;
  }
  
  .styles_Avatar-greet__image__1jhTB, .styles_Avatar-greet__text__1gk47 {
    width: 205px;
  }

  .styles_Avatar-greet__text__1gk47::after {
    border-top: 12px solid transparent;
    border-bottom: 12px solid var(--background-color-bottom);
    border-left: 12px solid var(--background-color-bottom);
    border-right: 12px solid transparent;
    left: 14px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Avatar-greet__2yVaK {
    font-size: 20px;
    width: 380px;
  }
  
  .styles_Avatar-greet__image__1jhTB, .styles_Avatar-greet__text__1gk47 {
    width: 225px;
  }

  .styles_Avatar-greet__text__1gk47::after {
    border-top: 13px solid transparent;
    border-bottom: 13px solid var(--background-color-bottom);
    border-left: 13px solid var(--background-color-bottom);
    border-right: 13px solid transparent;
    left: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Avatar-greet__2yVaK {
    font-size: 21.5px;
    width: 425px;
  }
  
  .styles_Avatar-greet__image__1jhTB, .styles_Avatar-greet__text__1gk47 {
    width: 250px;
  }

  .styles_Avatar-greet__text__1gk47::after {
    border-top: 14px solid transparent;
    border-bottom: 14px solid var(--background-color-bottom);
    border-left: 14px solid var(--background-color-bottom);
    border-right: 14px solid transparent;
    left: 16px;
  }
}
.styles_AboutMe__title__3QxJs {
  font-size: 1.5em;
}

.styles_AboutMe__name__3X4vV {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.styles_AboutMe__paragraph__2g4Lf {
  margin-bottom: 30px;
  line-height: 1.4;
}

.styles_paragraph__link__3X6c2 {
  color: var(--font-color);
  text-decoration: underline;
}

.styles_paragraph__img__YDDib {
  width: 16px;
}

.styles_AboutMe__section__292NR .styles_AboutMe__title__3QxJs {
  margin-bottom: 15px;
}

.styles_AboutMe__section__292NR .styles_AboutMe__paragraph__2g4Lf {
  margin-bottom: 10px;
}

.styles_AboutMe__paragraph__2g4Lf:last-child {
  margin-bottom: 30px;
}

@media screen and (min-width: 480px) {
  .styles_AboutMe__1bqY7 {
    font-size: 17px;
  }

  .styles_paragraph__img__YDDib {
    width: 17px;
  }
}

@media screen and (min-width: 768px) {
  .styles_AboutMe__1bqY7 {
    font-size: 18.5px;
  }
  
  .styles_paragraph__img__YDDib {
    width: 18.5px;
  }
}

@media screen and (min-width: 992px) {
  .styles_AboutMe__1bqY7 {
    font-size: 20px;
  }

  .styles_paragraph__img__YDDib {
    width: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_AboutMe__1bqY7 {
    font-size: 21.5px;
  }
  
  .styles_paragraph__img__YDDib {
    width: 21.5px;
  }
}

.styles_Project__egESM {
  height: 150px;
  border-radius: 12px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: box-shadow 0.15s linear;
}

.styles_Project__egESM:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 1);
}

.styles_Project__wrapper__2h7Ft {
  height: 100%;
  transition: transform 0.15s linear;
  position: relative
}

.styles_Project__egESM:hover .styles_Project__wrapper__2h7Ft {
  transform: scale(1.05);
}

.styles_Project__image__bjmGZ {
  width: 100%;
  height: 100%;
}

.styles_Project__new-text__1Y3LS {
  position: absolute;
  top: 19px;
  right: -40px;
  background-color: #1d88ff;
  padding: 0 40px;
  transform: rotateZ(45deg);
  font-size: 0.9em;
  font-weight: 700;
}

.styles_Project__description__c2mfg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  opacity: 0;
  transform: translateY(100%);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  transition: 0.15s linear;
  transition-property: opacity, transform;
}

.styles_Project__egESM:hover .styles_Project__description__c2mfg {
  opacity: 1;
  transform: translateY(0);
}

.styles_description__tools__2rEBp { 
  font-weight: 700;
}

.styles_description__link__2oS2d {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 120px;
  height: 35px;
  text-align: center;
  border-radius: 7px;
  background-color: var(--background-color-button);
}

.styles_description__link__2oS2d:first-child {
  margin-right: 20px;
}

.styles_description__link__2oS2d:hover {
  color: white;
  background-color: var(--font-strong-hover-color);
}

.styles_link__icon__gHWlf {
  margin-left: 5px;
}

@media screen and (min-width: 480px) {
  .styles_Project__egESM {
    height: 230px;
  }

  .styles_Project__new-text__1Y3LS {
    top: 20px;
    font-size: 1em;
  }

  .styles_description__link__2oS2d {
    width: 130px;
  }
}

@media screen and (min-width: 568px) {
  .styles_Project__egESM {
    height: 240px;
  }

  .styles_description__link__2oS2d {
    width: 135px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Project__egESM {
    height: 300px;
  }

  .styles_Project__new-text__1Y3LS {
    top: 30px;
    right: -35px;
    font-size: 1.1em;
  }

  .styles_description__link__2oS2d {
    width: 145px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Project__egESM {
    height: 320px;
  }

  .styles_description__link__2oS2d {
    width: 155px;
  }
}

@media screen and (min-width: 992px) {
  .styles_description__link__2oS2d {
    width: 165px;
  }
}


.styles_Projects-grid__4-viN {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}
.styles_Projects__title1__pP-D7 {
  font-size: 1.5em;
}

.styles_Projects__title2__H85gm {
  font-size: 2.5em;
  margin-bottom: 15px;
}

.styles_Projects__paragraph__A9Y3e {
  margin-bottom: 30px;
}

.styles_paragraph__emoji-search__HA3x6 {
  margin-right: 5px;
}

.styles_paragraph__img-platzi__1x-S- {
  width: 16px;
}

.styles_paragraph__link__3XrIB {
  color: var(--font-color);
  text-decoration: underline;
}

@media screen and (min-width: 480px) {
  .styles_Projects__3OGKw {
    font-size: 17px;
  }

  .styles_paragraph__img-platzi__1x-S- {
    width: 17px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Projects__3OGKw {
    font-size: 18.5px;
  }

  .styles_paragraph__img-platzi__1x-S- {
    width: 18.5px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Projects__3OGKw {
    font-size: 20px;
  }

  .styles_paragraph__img-platzi__1x-S- {
    width: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Projects__3OGKw {
    font-size: 21.5px;
  }

  .styles_paragraph__img-platzi__1x-S- {
    width: 21.5px;
  }
}
.styles_Contact__263ar {
  text-align: center;
}

.styles_Contact__title__O1vXW {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: var(--font-strong-hover-color);
}

.styles_Contact__paragraph__3G7Xx {
  margin-bottom: 15px;
  line-height: 1.4;
  font-weight: 400;
}

.styles_Contact__paragraph__3G7Xx:last-child {
  margin-bottom: 0;
}

.styles_paragraph__link__JKwXy {
  color: white;
  text-decoration: underline;
}

.styles_paragraph__link__JKwXy:hover {
  color: var(--font-strong-hover-color);
}

.styles_link__icon__6_i1N {
  margin-left: 5px;
}

@media screen and (min-width: 480px) {
  .styles_Contact__263ar {
    font-size: 17px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Contact__263ar {
    font-size: 18.5px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Contact__263ar {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Contact__263ar {
    font-size: 21.5px;
  }
}
.styles_Avatar404__image__3E7Gb {
  width: 300px;
  display: block;
  margin: 0 auto 20px;
}

.styles_Avatar404__title__3t9Eg {
  font-size: 2.5em;
  color: var(--font-strong-hover-color);
}

.styles_Avatar404__paragraph__2Vmpw {
  font-size: 1.25em;
}

.styles_Avatar404__title__3t9Eg, .styles_Avatar404__paragraph__2Vmpw {
  text-align: center;
}

@media screen and (min-width: 480px) {

  .styles_Avatar404__aj73O {
    font-size: 17px;
  }

  .styles_Avatar404__image__3E7Gb {
    width: 320px;
  }
}

@media screen and (min-width: 768px) {
  .styles_Avatar404__aj73O {
    font-size: 18.5px;
  }

  .styles_Avatar404__image__3E7Gb {
    width: 350px;
  }
}

@media screen and (min-width: 992px) {
  .styles_Avatar404__aj73O {
    font-size: 20px;
  }
  
  .styles_Avatar404__image__3E7Gb {
    width: 370px;
  }
}

@media screen and (min-width: 1200px) {
  .styles_Avatar404__aj73O {
    font-size: 21.5px;
  }
  
  .styles_Avatar404__image__3E7Gb {
    width: 400px;
  }
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

:root {
  --background-color-top: rgba(79, 164, 244, 0.6);
  --background-color-bottom: rgba(1, 16, 54, 1);
  --background-color-button: rgba(61, 61, 61, 0.35);
  --font-strong-hover-color: #cca266;
  --font-color: #fff;
}

*, *:before, *:after {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', sans-serif;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  background-color: rgba(1, 16, 54, 1);
  background-color: var(--background-color-bottom);
  color: #fff;
  color: var(--font-color);
  overflow-x: hidden;
}

ul, li, h1, h2, h3, p, button, figure {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

a {
  text-decoration: none;
  transition: all 0.15s ease;
}

a:hover {
  color: #cca266;
  color: var(--font-strong-hover-color);
}

.hidden {
  display: none;
}
.fadeIn {
  -webkit-animation: fadeIn 0.7s ease-in backwards;
          animation: fadeIn 0.7s ease-in backwards;
}

.fadeInUp {
  -webkit-animation: fadeInUp 0.7s ease-in backwards;
          animation: fadeInUp 0.7s ease-in backwards;
}

.menu-fadeInRight {
  -webkit-animation: fadeInRight 0.25s ease-in-out forwards;
          animation: fadeInRight 0.25s ease-in-out forwards;
}

.menu-fadeIn {
  -webkit-animation: fadeIn 0.25s ease-in-out forwards;
          animation: fadeIn 0.25s ease-in-out forwards;
}

.delay-1 {
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}

.delay-2 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}

.delay-3 {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
